.post-content .mpp-profile-wrap,
.post-content .mpp-enhanced-profile-wrap {
	h1, h2 , h3, h4 , h5, h6 {
		clear: none;
	}
	h2 {
		margin: 0;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 10px;
	}
}
.mpp-profile-wrap {
	margin: 0 auto;
	padding: 3%;
	border-radius: 5px;
	margin-bottom: 1.2em;
	margin-bottom: 20px;
	line-height: 1.3;
	&.round .mpp-profile-image-wrapper {
		border-radius: 50%;
		overflow: hidden;
	}
	&.round .mpp-profile-image-wrapper img {
		border-radius: 50%;
	}
	h1,h2,h3,h4,h5,h6 {
		clear: none;
	}
	h2 {
		margin-top: 0;
		margin-bottom: 10px;
	}
	h2:before {
		display: none;
	}
	.mpp-profile-image-wrapper {
		position: relative;
		float: left;
		line-height: 1.1;
		z-index: 1000;
		background: #ddd;
		position: relative;
		min-width: 150px;
		min-height: 150px;
		max-width: 150px;
		max-height: 150px;
		width: 100%;
		margin-right: 20px;
		margin-bottom: 20px;
		button {
			position: relative;
			min-width: 150px;
			min-height: 150px;
			max-width: 150px;
			max-height: 150px;
			z-index: 1000;
		}
		.mpp-profile-image-square {
			position: relative;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 5;
		}
		.mpp-profile-image-square img {
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 5;
		}
		.mpp-content-wrap {
			display: block;
			padding: 0 15px 0 15px;
			-ms-flex: 3 0 0px;
			flex: 3 0 0;
		}
		.mpp-profile-name {
			font: 1.4em;
			line-height: 1.2;
		}
		@media only screen and (max-width: 600px) {
			flex: auto;
		}
	}
	.mpp-gutenberg-view-posts {
		clear: both;
		padding-top: 20px;
	}
	.mpp-profile-view-posts {
		clear: both;
		display: block;
		width: 100%;
		text-align: center;
		padding: 10px 20px;
		background-color: #cf6d38;
		color: #FFF;
		a,
		a:hover,
		a:visited {
			display: block;
			width: 100%;
			height: 100%;
			color: #FFF;
			text-decoration: none;
		}
	}
}

.mpp-enhanced-profile-wrap.regular {
	position: relative;
	margin: 0 auto;
	line-height: 1.5;
	&.round .mpp-profile-image-wrapper {
		border-radius: 50%;
		overflow: hidden;
	}
	&.round .mpp-profile-image-wrapper img {
		border-radius: 50%;
	}
	h1,h2,h3,h4,h5,h6 {
		clear: none;
	}
	h2:before {
		display: none;
	}
	.mpp-profile-image-wrapper {
		position: relative;
		float: left;
		line-height: 1.1;
		z-index: 1000;
		background: #ddd;
		position: relative;
		min-width: 150px;
		min-height: 150px;
		max-width: 150px;
		max-height: 150px;
		width: 100%;
		margin-right: 20px;
		margin-bottom: 20px;
		@media only screen and (max-width: 400px) {
			float: none;
			text-align: center;
			margin: 0 auto;
		}
		button {
			position: relative;
			min-width: 150px;
			min-height: 150px;
			max-width: 150px;
			max-height: 150px;
			z-index: 1000;
		}
		.mpp-profile-image-square {
			position: relative;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 5;

		}
		.mpp-profile-image-square img {
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 5;
		}
		.mpp-content-wrap {
			display: block;
			padding: 0 15px 0 15px;
			-ms-flex: 3 0 0px;
			flex: 3 0 0;
			@media only screen and (max-width: 400px) {
				display: block;
				text-align: center;
				margin: 0 auto;
			}
		}
		.mpp-profile-name {
			font: 1.4em;
			line-height: 1.2;
		}
		@media only screen and (max-width: 600px) {
			flex: auto;
		}
	}
	.mpp-content-wrap {
		@media only screen and (max-width: 400px) {
			display: block;
			text-align: center;
			margin: 0 auto;
		}
	}
	.mpp-gutenberg-view-posts {
		clear: both;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.mpp-profile-view-posts {
		clear: both;
		display: block;
		float: left;
		text-align: center;
		padding: 10px 20px;
		margin-top: 20px;
		margin-right: 20px;
		background-color: #cf6d38;
		color: #FFF;
		a,
		a:hover,
		a:visited {
			display: block;
			width: 100%;
			height: 100%;
			color: #FFF;
			text-decoration: none;
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		@media only screen and (max-width: 400px) {
			display: block;
			width: 90%;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 10px;
		}
	}

	.mpp-profile-view-website {
		display: block;
		float: left;
		text-align: center;
		padding: 10px 20px;
		margin-top: 20px;
		background-color: #333;
		color: #FFF;
		a,
		a:hover,
		a:visited {
			display: block;
			width: 100%;
			height: 100%;
			color: #FFF;
			text-decoration: none;
		}
		@media only screen and (max-width: 400px) {
			display: block;
			width: 90%;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 10px;
		}
	}
}


/* Icon Sizes */
$sizes: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24;
@each $size in $sizes {
	.mpp-profile-wrap.mt-font-size-#{$size},
	.mpp-enhanced-profile-wrap.regular .mt-font-size-#{$size},
	.mpp-enhanced-profile-wrap.profile .mt-font-size-#{$size},
	.mpp-enhanced-profile-wrap.compact .mt-font-size-#{$size},
	.mpp-profile-text.mt-font-size-#{$size} {
		font-size: #{$size}px;
		p,
		div {
			font-size: #{$size}px;
		}

	}
}

/* For Social Media */
.mpp-social {
	margin-top: 15px;
	svg {
		max-width: 32px;
		max-height: 32px;
		margin-right: 10px;
	}
	a,
	a:hover,
	a:visited {
		text-decoration: none !important;
	}
}
/* Social Media Colors */
$colors: twitter #00aced,
	amazon #000000,
	behance #0692e9,
	blogger #fb8f3d,
	codepen #000000,
	dribble #F46899,
	dropbox #018BD3,
	eventbrite #f6682F,
	facebook #3b5998,
	flickr #ff0084,
	foursquare #0072b1,
	ghost #000000,
	github #070709,
	google-plus #CF3D2E,
	instagram #A1755C,
	linkedin #0085AE,
	feed #f26522,
	medium #000000,
	path #000000,
	pinterest #CC2127,
	pocket #000000,
	polldaddy #bc0b0b,
	reddit #000000,
	skype #01AEF2,
	spotify #1ed760,
	squarespace #000000,
	stumbleupon #EB4823,
	telegram #000000,
	tumblr-alt #314E6C,
	twitch #4b367c,
	twitter-alt #00aced,
	vimeo #1ab7ea,
	wordpress #21759b,
	youtube #bb0000
;
.mpp-social {
	clear: both;
}
.mpp-social svg {
	@each $color in $colors {
		&.icon-#{nth($color,1)} {
			fill: #{nth($color,2)};
		}
	}
}

.mpp-enhanced-profile-wrap.profile {
	padding: 10px;
	h2 {
		margin: 0;
		font-size: 0.8em;
		font-weight: normal;
		margin-bottom: 5px;
		&:before {
			display: none;
		}
		@media only screen and (max-width: 400px) {
			text-align: center;
		}
	}
	&.round .profile-avatar {
		border-radius: 50%;
	}
	.mpp-profile-image-wrapper {
		position: relative;
		float: left;
		line-height: 1.0;
		z-index: 1000;
		min-width: 150px;
		min-height: 150px;
		max-width: 150px;
		max-height: 150px;
		margin-right: 15px;

		@media only screen and (max-width: 400px) {
			float: none;
			text-align: center;
			margin: 0 auto;
		}
	}
	.mpp-profile-meta {
		clear: both;
		margin: 10px 0;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.alignleft {
		max-width: none;
		float: left;
		@media only screen and (max-width: 400px) {
			float: none;
			text-align: center;
			margin: 0 auto;
		}
	}
	.alignright {
		max-width: none;
		@media only screen and (max-width: 400px) {
			float: none;
			text-align: center;
			margin: 0 auto;
		}
	}
}

/* For Tabbed Theme */
.mpp-author-tabbed {
	padding: 10px;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	.mpp-social {
		margin-top: 0;
	}
	.mpp-author-social-wrapper {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		margin-bottom: 10px;
	}
	h1, h2, h3, h4, h5 , h6 {
		clear: none;
	}
	h2 {
		font-size: 0.8em;
		font-weight: normal;
		margin-bottom: 5px;
		&:before {
			display: none;
		}
	}
	.mpp-tab-wrapper {
		clear: both;
	}
	.mpp-author-heading {
		float: left;
		@media only screen and (max-width: 600px) {
			float: none;
			text-align: center;
			margin: 0 auto;
			width: 90%;
		}
	}
	.mpp-author-social {
		float: right;
		@media only screen and (max-width: 600px) {
			float: none;
			text-align: center;
			margin: 0 auto;
		}
	}
	.mpp-author-heading .mpp-author-profile-heading {
		display: inline-block;
		background: #42737b;
		color: #FFF;
		padding: 10px 20px;
		font-size: 14px;
		text-transform: uppercase;
		@media only screen and (max-width: 600px) {
			display: block;
			margin-bottom: 10px;
		}
	}
	.mpp-author-profile-sub-heading {
		font-size: 14px;
		line-height: 1.1;
		max-width: 150px;
		text-align: center;
	}
	&.round .profile-avatar {
		border-radius: 50%;
	}
	.mpp-profile-image-wrapper {
		clear: both;
		position: relative;
		float: left;
		line-height: 1.0;
		z-index: 1000;
		margin-right: 30px;
		img {
			min-width: 150px;
			min-height: 150px;
			max-height: 150px;
			max-width: 150px;
		}


	}
	.mpp-profile-meta {
		clear: both;
		margin: 10px 0;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.mpp-author-profile-title {
		color: gray;
		text-transform: uppercase;
		font-size: 12px;
	}
	.mpp-tabbed-profile-information {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.mpp-tab {
		display: none;
	}
	.mpp-tab-active {
		display: block;
	}
	ul.mpp-author-tabs {
		position:relative;
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;

		li {
			cursor: pointer;
			position: relative;
			display: inline-block;
			min-width: 200px;
			background: #42737b;
			margin: 0 5px 0 0;
			text-align: center;
			margin-right: 10px;
			color: #FFF;
			padding: 10px 20px;
			font-size: 16px;
			@media only screen and (max-width: 500px) {
				width: 100%;
				display: block;
			}

			&.active:after {
				content: "";
				display: block;
				position: absolute;
				bottom: -10px;
				left: calc(50% - 10px);
				border-top: 10px solid #42737b;
				border-top-color: #42737b;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				@media only screen and (max-width: 500px) {
					display: none;
				}
			}
		}
		li:last-child {
			margin-right: 0;
		}
		li.mpp-tab-posts {
			background: #30424b;
			&.active:after {
				border-top: 10px solid #30424b;
				border-top-color: #30424b;
			}
		}
	}
	ul.mpp-author-tab-content {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			margin: 0;
		}
		&.white {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #FFFFFF;
				border: 1px solid darken(#FFFFFF, 5%);
				padding: 10px 20px;
				text-decoration: none;
				color: #333;
			}
			a:hover {
				background: darken(#FFFFFF, 5%);
			}
		}
		&.light {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #f7f7f7;
				border: 1px solid darken(#f7f7f7, 10%);
				padding: 10px 20px;
				text-decoration: none;
				color: #333;
			}
			a:hover {
				background: darken(#f7f7f7, 10%);
			}
		}
		&.black {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #333;
				color: #FFF;
				border: 1px solid darken(#333, 10%);
				padding: 10px 20px;
				text-decoration: none;
			}
			a:hover {
				background: darken(#333, 10%);
			}
		}
		&.magenta {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #FF00FF;
				color: #FFF;
				border: 1px solid darken(#FF00FF, 10%);
				padding: 10px 20px;
				text-decoration: none;
			}
			a:hover {
				background: darken(#FF00FF, 10%);
			}
		}
		&.blue {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #0009c1;
				color: #FFF;
				border: 1px solid darken(#0009c1, 10%);
				padding: 10px 20px;
				text-decoration: none;
			}
			a:hover {
				background: darken(#0009c1, 10%);
			}
		}
		&.green {
			a,
			a:hover,
			a:visited {
				display: block;
				background: #03ac27;
				color: #FFF;
				border: 1px solid darken(#03ac27, 10%);
				padding: 10px 20px;
				text-decoration: none;
			}
			a:hover {
				background: darken(#03ac27, 10%);
			}
		}
	}
}

/* For Compact Theme */
.mpp-enhanced-profile-wrap.compact {
	text-align: center;
	padding: 10px;
	max-width: 400px;
	margin: 0 auto;
	line-height: 1.1;
	&.center {
		margin: 0 auto;
	}
	&.left {
		margin-left: 0;
	}
	&.right {
		margin-right: 0;
	}
	.mpp-social svg {
		margin-right: 0;
	}
	h2 {
		font-size: 0.8em;
		font-weight: normal;
		margin-bottom: 5px;
		&:before {
			display: none;
		}
	}
	&.round .profile-avatar {
		border-radius: 50%;
	}
	.mpp-profile-image-wrapper {
		position: relative;
		text-align: center;
		line-height: 1.0;
		z-index: 1000;
		min-width: 150px;
		min-height: 150px;
		max-width: 150px;
		max-height: 150px;
		margin: 0 auto;
	}
	.mpp-profile-text {
		line-height: 1.1;
	}
	.mpp-compact-meta {
		clear: both;
		margin: 10px 0;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.mpp-profile-view-posts {
		clear: both;
		display: block;
		text-align: center;
		padding: 10px 20px;
		margin-top: 20px;
		margin-right: 20px;
		background-color: #cf6d38;
		color: #FFF;
		margin-bottom: 10px;
		a,
		a:hover,
		a:visited {
			display: block;
			width: 100%;
			height: 100%;
			color: #FFF;
			text-decoration: none;
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.mpp-profile-view-website {
		display: block;
		text-align: center;
		padding: 10px 20px;
		margin-top: 20px;
		background-color: #333;
		color: #FFF;
		a,
		a:hover,
		a:visited {
			display: block;
			width: 100%;
			height: 100%;
			color: #FFF;
			text-decoration: none;
		}
	}
}